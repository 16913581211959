import React from 'react'

import Layout from "../../../components/Layout"
import CaseStudyStep from '../../../components/CaseStudyStep'
import CaseStudyNav from '../../../components/CaseStudyNav'

export default class DiagnosticStepThree extends React.Component {

  render() {
    return (
      <div>
        <Layout>
          <CaseStudyNav 
            linkOneClass="aga-casestudy-link active"
            linkTwoClass="aga-casestudy-link"
            linkThreeClass="aga-casestudy-link"
          />
          <CaseStudyStep
            title="Step #3"
            question="What additional steps would you recommend to diagnose and manage this patient?"
            body={[
              <h3>Expert Opinion</h3>,
              <p>Because the FODMAPs diet did not significantly mitigate Jennifer’s symptoms, I would consider referring her to a GI specialist now to extend the workup and/or provide additional strategies to mitigate symptoms. Although statistically Jennifer is still likely to have a form of irritable bowel syndrome that might be managed in a primary care setting, referring her to a specialist at this point is a more value-driven decision. This is because Jennifer will likely now need additional, and costly, additional testing such as a luminal exam (esophagastroduodendoscopy or colonoscopy) and/or a cross sectional imaging test (such as an MRI or CT scan), particularly in the context of her borderline fecal calprotectin test.</p>,
              <p>Cross-sectional imaging such as MRI or CT should not be considered routinely at the primary-care level this early on in the diagnostic evaluation in the absence of warning signs or symptoms given the cost burden (and, should a CT scan be chosen, the radiation burden). In addition, because the timing and sequence of cross-sectional imaging vs. endoscopic evolving in the diagnosis of possible IBD are continuing to evolve, the judgment of a GI specialist is required at this point.</p>,
              <p>I would provide the specialist with a copy of my last office notes, as well as all lab studies that have been performed to date. The astute gastroenterologist may use additional history taking and potentially find other gastrointestinal irritants that may be associated her symptoms (for example gum chewing can lead to bloating and abdominal pain due to the introduction of luminal gas) — a habit that I or any other primary-care physician may more easily miss.</p>
            ]}
            slideLink="#"
            cmeLink="/cme"
            backLink="/steps/step-2"
            backText="Back to Step 2"
            nextLink="/cases/medication-management-gastroenterology"
            nextText="Go to Case Two"
          />
        </Layout>
      </div>
    )
  }
}